import React from "react";

// Material Components.
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function Page(props) {
    return (
        <Paper>
            <Box
                padding={5}
            >
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: 4
                    }}
                >
                    {props.title}
                </Typography>

                {props.children}
            </Box>
        </Paper>
    );
}

export default Page;