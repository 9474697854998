export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// formatParagraph return a string without unnecessary line breaks inside
// and remove all leading and trailing white spaces.
// TODO:
// - Make improvements and add special cases.
export function formatParagraph(paragraph) {

    let cleanedParagraph = "";

    let lines = paragraph.split("\n");

    for (let line of lines) {

        // Clean spaces.
        line = line.trim();

        // If is a blank line, add.
        if (line === "") {
            cleanedParagraph = cleanedParagraph + "\n\n"
            continue
        }

        // If the last element in result is a dot, add line break.
        if (cleanedParagraph.length > 0 && cleanedParagraph[cleanedParagraph.length-1] === ".") {
            cleanedParagraph = cleanedParagraph + "\n" + line
            continue
        }

        if (cleanedParagraph === "") {
            cleanedParagraph = line
            continue
        }

        cleanedParagraph = cleanedParagraph + " " + line
    }

    return cleanedParagraph.trim();
}