import React from "react";

// Components.
import PersonalizedMessage from "../../components/PersonalizedMessage/PersonalizedMessage";

function NotFound() {
    return (
        <PersonalizedMessage
            title="Aish!"
            secondary="Error 404, página no encontrada 😔"
        />
    );
}

export default NotFound;