import React, {useState} from "react";

import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";

// Components.
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// Contexts.
import {AppContext, defaultAppContext} from "./contexts/AppContext";
import {UserContext, defaultUserContext} from "./contexts/UserContext";

// Pages.
import AuthLogin from "./pages/Auth/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Dashboard/Home/Home";
import MaterialApp from "./MaterialApp";
import NotFound from "./pages/NotFound/NotFound";
import SecurityChangePassword from "./pages/Dashboard/Security/ChangePassword";
import SecurityMain from "./pages/Dashboard/Security/Main";

function App() {

    const [appContext, setAppContext] = useState(defaultAppContext);
    const [userContext, setUserContext] = useState(defaultUserContext);

    return (
        <AppContext.Provider
            value={{
                value: appContext,
                setValue: setAppContext
            }}
        >
            <UserContext.Provider
                value={{
                    value: userContext,
                    setValue: setUserContext
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route element={<MaterialApp/>}>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route index element={<Navigate to="dashboard/home"/>}/>
                                <Route path="dashboard" element={<Dashboard/>}>
                                    <Route index element={<Navigate to="home"/>}/>
                                    <Route path="home" element={<Home/>}/>
                                    <Route path="security">
                                        <Route index element={<Navigate to="main"/>}/>
                                        <Route path="main" element={<SecurityMain/>}/>
                                        <Route path="change_password" element={<SecurityChangePassword/>}/>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="/login" exact element={<AuthLogin/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </UserContext.Provider>
        </AppContext.Provider>
    );
}

export default App;