import Cookies from "js-cookie"

export const saveSessionTokens = (accessToken, refreshToken) => {
    Cookies.set("access_token", accessToken, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_COOKIE_SECURE,
        expires: 1,
    });
    Cookies.set("refresh_token", refreshToken, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_COOKIE_SECURE,
        expires: 180,
    });
}

export const getSessionTokens = () => {
    let accessToken = Cookies.get("access_token");
    let refreshToken = Cookies.get("refresh_token");

    return {accessToken, refreshToken}
}

export const deleteSessionTokens = () => {
    Cookies.remove("access_token", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_COOKIE_SECURE,
    });
    Cookies.remove("refresh_token", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_COOKIE_SECURE,
    });
}