import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useSearchParams} from "react-router-dom";

// Components.
import Copyright from "../../../components/Copyright/Copyright";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Utils.
import {apiAuthInstance} from "../../../utils/api";
import {getSessionTokens, saveSessionTokens} from "../../../utils/session_tokens";
import {capitalizeFirstLetter} from "../../../utils/strings";
import {killSession} from "../../../utils/kill_session";

function Login() {

    // Contexts.
    const appContext = useContext(AppContext);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // States.
    const [loading, setLoading] = useState(false);
    const [retry, setRetry] = useState(0);

    // Form states.
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Form error states.
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");

    // Connection error states.
    const [connectionError, setConnectionError] = useState(false);
    const [connectionErrorMessage, setConnectionErrorMessage] = useState("");

    // Check user session.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Clean errors.
        setConnectionError(false);
        setConnectionErrorMessage("");

        // Load user tokens.
        let tokens = getSessionTokens();

        // Check if exist cookie with refresh token.
        // If exists validate.
        // If not exist, kill session and redirect.
        if (tokens.refreshToken) {

            apiAuthInstance.post("/revalidate_session", {
                "refresh_token": tokens.refreshToken
            }).then((response) => {

                // Check if is valid.
                if (response.data.auth.valid_session) {

                    // Session is valid!
                    saveSessionTokens(response.data.auth.access_token, tokens.refreshToken);

                    // Disable loading.
                    appContext.setValue(prevState => ({
                        ...prevState,
                        loading: false,
                    }));

                    if (searchParams.get("redirect") ?? false) {
                        window.location.replace(searchParams.get("redirect"));

                    } else {
                        navigate("/dashboard/home");

                    }

                } else {

                    // Disable loading.
                    appContext.setValue(prevState => ({
                        ...prevState,
                        loading: false,
                    }));

                    // Session is not valid or server error.
                    // Sign out.
                    killSession().then(() => {});
                }

            }).catch((error) => {
                // TODO: Handler all errors.
                // Session is not valid or server is down.

                // Disable loading.
                appContext.setValue(prevState => ({
                    ...prevState,
                    loading: false,
                }));

                // If server response is unauthorized.
                if (error.response?.status === 401) {
                    // Sign out.
                    killSession().then(() => {});
                }

                // If server is down or user has no connection.
                if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
                    // Set error.
                    setConnectionError(true);
                    setConnectionErrorMessage("¡Sin conexión con el servidor! 😱");
                }
            });
        } else {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        }

    }, [retry]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        setLoading(true);

        // Clean errors.
        setFormError(false);
        setFormErrorMessage("");

        apiAuthInstance.post("/login", {
            "email": email,
            "password": password,
        }).then(response => {

            // Login is success.
            if (response.data?.auth?.authenticated ?? false) {
                saveSessionTokens(response.data.auth.access_token, response.data.auth.refresh_token);

                // Clear form inputs.
                setEmail("");
                setPassword("");

                // Disable loading.
                setLoading(false);

                if (searchParams.get("redirect") ?? false) {
                    window.location.replace(searchParams.get("redirect"));

                } else {
                    navigate("/dashboard/home");

                }
            }

            // Error?
            // Disable loading.
            setLoading(false);

        }).catch(error => {

            // Set error.
            setFormError(true);

            if (error.response?.data?.message ?? false) {
                setFormErrorMessage(error.response.data.message);

            } else {
                setFormErrorMessage("error interno, intente más tarde");

            }

            // Disable loading.
            setLoading(false);
        });
    };

    return (
        !connectionError ?
            (
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 100,
                                height: 100,
                                padding: 1,
                                marginBottom: 2,
                            }}
                            src={process.env.PUBLIC_URL + "/android-chrome-192x192.png"}
                        />

                        <Typography
                            component="h1"
                            variant="h5"
                        >
                            Inicia Sesión
                        </Typography>

                        {
                            formError ?
                                <Typography component="p" variant="subtitle1" color="#d32f2f">
                                    {capitalizeFirstLetter(formErrorMessage)}
                                </Typography>
                                : ""
                        }

                        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                error={formError}
                                disabled={loading}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                error={formError}
                                disabled={loading}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{mt: 3, mb: 2}}
                            >
                                Iniciar
                            </Button>

                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        style={{cursor: "pointer"}}
                                        onClick={() => navigate("/lost_password")}
                                        variant="body2"
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </Grid>

                                <Grid item>
                                    <Link
                                        style={{cursor: "pointer"}}
                                        onClick={() => navigate("/sign_up")}
                                        variant="body2"
                                    >
                                        {"¡Registrate!"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Copyright
                        sx={{
                            mt: 8,
                            mb: 4
                        }}
                    />
                </Container>
            )
            :
            (
                <PersonalizedMessage
                    title="Aish!"
                    secondary={connectionErrorMessage}
                    actionText="Reintentar"
                    actionOnClick={_ => setRetry(prevState => prevState + 1)}
                />
            )
    );
}

export default Login;