import React, {useContext, useEffect, useState} from "react";

import {useSnackbar} from "notistack";

// Components.
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Utils.
import {apiUsersInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function ChangePassword() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputCurrentPassword, setInputCurrentPassword] = useState("");
    const [inputNewPassword, setInputNewPassword] = useState("");
    const [inputConfirmNewPassword, setInputConfirmNewPassword] = useState("");

    // Error states.
    const [errorNewPassword, setErrorNewPassword] = useState(false);

    // Validate inputNewPassword and inputConfirmNewPassword.
    useEffect(() => {

        if ((inputNewPassword !== "" || inputConfirmNewPassword !== "") && (inputNewPassword !== inputConfirmNewPassword)) {
            setErrorNewPassword(true);
            return;
        }

        setErrorNewPassword(false);

    }, [inputNewPassword, inputConfirmNewPassword]);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputCurrentPassword("");
        setInputNewPassword("");
        setInputConfirmNewPassword("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiUsersInstance.put("/account/security/change_password", {
            "current_password": inputCurrentPassword,
            "new_password": inputNewPassword,
            "confirm_new_password": inputConfirmNewPassword,
        }).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Cambiar contraseña">

            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-current-password"
                        label="Contraseña actual"
                        type="password"
                        value={inputCurrentPassword}
                        onChange={(event) => setInputCurrentPassword(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-new-password"
                        label="Nueva contraseña"
                        type="password"
                        error={errorNewPassword}
                        value={inputNewPassword}
                        onChange={(event) => setInputNewPassword(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-confirm-new-password"
                        label="Confirmar nueva contraseña"
                        type="password"
                        error={errorNewPassword}
                        value={inputConfirmNewPassword}
                        onChange={(event) => setInputConfirmNewPassword(event.target.value)}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default ChangePassword;