import {createContext} from "react";

// Default value.
export const defaultUserContext = {
    id: "",
    name: "",
    last_name: "",
    role: 0,
    state: 0,
}

// Define contexts.
export const UserContext = createContext({
    value: defaultUserContext,
    setValue: () => {},
});