import React from "react";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function PersonalizedMessage(props) {
    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography
                component={props.small ? "p" : "h1"}
                variant={props.small ? "h5" : "h3"}
            >
                {props.title}
            </Typography>

            <Typography
                component={props.small ? "p" : "h2"}
                variant={props.small ? "h7" : "h5"}
            >
                {props.secondary}
            </Typography>

            {
                props.actionText && props.actionOnClick ?
                    <Button
                        variant="outlined"
                        size={props.small ? "small" : "medium"}
                        sx={{
                            marginTop: 4,
                        }}
                        onClick={_ => props.actionOnClick()}
                    >
                        {props.actionText}
                    </Button>
                    :
                    <></>
            }
        </Box>
    );
}

export default PersonalizedMessage;