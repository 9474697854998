import React from "react";

// Material Components.
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function DrawerItemList({drawerOpen, title, icon, ...props}) {
    return (
        <ListItem disablePadding sx={{display: "block"}}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2.5,
                }}
                {...props}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {icon}
                </ListItemIcon>

                <ListItemText
                    primary={title}
                    sx={{opacity: drawerOpen ? 1 : 0}}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default DrawerItemList;