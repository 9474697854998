import axios from "axios";

// Utils.
import {bearerTokenAuthorizationInterceptor, expireTokenInterceptor} from "./axios_interceptors";

export const apiAuthInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API + "/auth",
    timeout: 60000
});

export const apiUsersInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API + "/users",
    timeout: 10000
});

// Use interceptors.
bearerTokenAuthorizationInterceptor(apiUsersInstance);
expireTokenInterceptor(apiUsersInstance, apiAuthInstance);