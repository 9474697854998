// Utils.
import {apiAuthInstance} from "./api";
import {deleteSessionTokens, getSessionTokens} from "./session_tokens";

// killSession remove cookies and invalidate tokens in database.
export const killSession = async () => {

    let tokens = getSessionTokens();

    // Check if exist refreshToken.
    // If exist refreshToken, delete from database.
    if (tokens.refreshToken) {
        await apiAuthInstance.delete("/sign_out", {
            data: {
                refresh_token: tokens.refreshToken
            }
        }).then(() => {

        }).catch(() => {

        });
    }

    await deleteSessionTokens();
}