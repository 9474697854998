import {createContext} from "react";

// Default value.
export const defaultAppContext = {
    loading: false,
}

// Define contexts.
export const AppContext = createContext({
    value: defaultAppContext,
    setValue: () => {},
});