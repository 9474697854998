import React from "react";

import {useNavigate} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";

// Material Components.
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import KeyIcon from '@mui/icons-material/Key';

// Material Styles.
import {useTheme} from '@mui/material/styles';

function Main() {

    // Theme.
    const theme = useTheme();

    // Navigate.
    const navigate = useNavigate();

    return (
        <Page title="Seguridad">
            <List>
                <ListItemButton
                    onClick={() => navigate("../change_password")}
                >
                    <ListItemAvatar>
                        <Avatar
                            style={{
                                background: theme.palette.secondary.main
                            }}
                        >
                            <KeyIcon/>
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        primary="Cambiar contraseña"
                        secondary={"¿Tu contraseña no es segura o alguien la ha vulnerado? ¡Cambiala aquí!"}
                    />
                </ListItemButton>
            </List>
        </Page>
    );
}

export default Main;