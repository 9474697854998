import React from "react";

// Material Components.
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function Loading(props) {
    return (
        <Backdrop
            sx={{
                color: "#ffffff",
                zIndex: (theme) => theme.zIndex.drawer + 2
            }}
            {...props}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid
                    item
                >
                    <CircularProgress color="inherit"/>
                </Grid>
                <Grid
                    item
                >
                    <Typography
                        variant="subtitle1"
                    >
                        Cargando...
                    </Typography>
                </Grid>
            </Grid>
        </Backdrop>
    );
}

export default Loading;