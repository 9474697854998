import React, {useContext, useEffect} from "react";

import {Outlet, useLocation} from "react-router-dom";

import ReactGA from "react-ga4";

import {SnackbarProvider} from "notistack";

// Fonts.
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Components.
import Loading from "./components/Loading/Loading";

// Contexts.
import {AppContext} from "./contexts/AppContext";

// Material Styles.
import {createTheme, ThemeProvider} from "@mui/material/styles";

// Theme.
const materialTheme = createTheme({
    palette: {
        primary: {
            main: "#004aad",
        },
        secondary: {
            main: "#f4732a",
            contrastText: "#ffffff"
        },
    },
});

// Google Analytics integration.
ReactGA.initialize("G-MDXB3E9DFC");

function MaterialApp() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Location.
    const location = useLocation();

    // Send the page where the user is in to Google Analytics.
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname + location.search});

    }, [location]);

    return (
        <ThemeProvider theme={materialTheme}>
            <Loading
                open={appContext.value.loading}
            />

            <SnackbarProvider
                autoHideDuration={3000}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >

                <Outlet/>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default MaterialApp;